export default {
    googleMapsCallback: 'window.pinnacle.initMaps',
    styles: [
        {
            "featureType": "all",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "saturation": 36
                },
                {
                    "color": "#333333"
                },
                {
                    "lightness": 40
                }
            ]
        },
        {
            "featureType": "all",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "featureType": "all",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#f6f6f6"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#fefefe"
                },
                {
                    "lightness": 17
                },
                {
                    "weight": 1.2
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ebebec"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e2d3dc"
                },
                {
                    "lightness": "0"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#d0b1c4"
                },
                {
                    "lightness": "00"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 17
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 29
                },
                {
                    "weight": 0.2
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 18
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f2f2f2"
                },
                {
                    "lightness": 19
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#b781a0"
                },
                {
                    "lightness": "0"
                }
            ]
        }
    ],
    init() {
        if (document.querySelector('.mapbox')) {
            this.enqueueGoogleMapsApi();
        }
    },
    enqueueGoogleMapsApi() {
        let s = document.createElement('script'); s.type = 'text/javascript';
        s.src = `https://maps.googleapis.com/maps/api/js?key=${wordpress_globals.google_maps_api_key}&callback=${this.googleMapsCallback}`;
        s.async = 1;
        s.defer = 1;
        let c = document.getElementsByTagName('script')[0];
        c.parentNode.insertBefore(s, c);
    },
    initMaps() {
        this.initLocationMaps();
    },
    initLocationMaps() {
        if (document.querySelector('.mapbox')) {
            Array.from(document.querySelectorAll('.mapbox'))
                .forEach(el => {
                    let location = new google.maps.LatLng(el.dataset.lat, el.dataset.lng);
                    let map = document.createElement('div');
                    let markerIcon = {
                        url: '/wp-content/themes/class/dist/images/icons/map-marker.png',
                        size: new google.maps.Size(120, 172),
                        scaledSize: new google.maps.Size(60, 86),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(30, 86)
                    };

                    el.googleMap = new google.maps.Map(map, {
                        zoom: 15,
                        center: location,
                        disableDefaultUI: true,
                        draggable: false,
                        styles: this.STYLES
                    });

                    el.googleMap.markers = [];
                    el.googleMap.markers.push(new google.maps.Marker({
                        position: location,
                        icon: markerIcon,
                        map: el.googleMap
                    }));

                    el.insertAdjacentElement('afterbegin', map);
                    el.dataset.googleMap = 'initialized';
                });
        }
    }
};
