import Swiper, {Pagination} from 'swiper';

class Swipers {

    constructor() {
        if (document.querySelectorAll('.swiper-container')) {
            this.initLatestPostsSlider();
            this.initSlider();
        }
    }

    initLatestPostsSlider() {
        if (document.querySelector('.swiper-latest-posts')) {

            Swiper.use([Pagination]);

            Array.from(document.querySelectorAll('.swiper-latest-posts'))
                .forEach(el => {
                    el.swiper = new Swiper(el, {
                        pagination: {
                            el: '.swiper-pagination',
                            type: 'bullets',
                            clickable: true,
                        },
                        slidesPerView: 1,
                        slidesPerGroup: 1,
                        spaceBetween: 20,
                        breakpoints: {
                            768: {
                                slidesPerView: 2,
                                slidesPerGroup: 2,
                            },
                            992: {
                                slidesPerView: 4,
                                slidesPerGroup: 4,
                                spaceBetween: 20,
                            },
                        }
                    });
                });
        }
    }

    initSlider() {
        if (document.querySelector('.swiper-slider')) {
            Array.from(document.querySelectorAll('.swiper-slider'))
                .forEach(el => {
                    el.swiper = new Swiper(el, {
                        slidesPerView: 'auto',
                        centeredSlides: true,
                        spaceBetween: 20,
                        on: {
                            init: function () {
                                this.slideTo(1);
                            }
                        }
                    });
                });
        }
    }
}

export default Swipers;
