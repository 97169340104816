/* globals jQuery, wordpress_globals */

import "../sass/theme.scss";

import $ from 'jquery';
import svgxhr from 'webpack-svgstore-plugin/src/helpers/svgxhr';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import Swipers from './modules/Swipers';
import nav from './modules/nav';
import maps from './modules/maps';

window.pinnacle = {
	init: function() {
		this.helpers.initSvg();

		new Swipers();
		nav.init();
		maps.init();

		//Allow user to interrupt auto-scrolling
		$(document).bind('scroll mousedown wheel keyup', function(e) {
			if(e.which || e.type === 'mousedown' || e.type === 'mousewheel') {
				$(document.scrollingElement).stop();
			}
		});
	},
	initMaps: function () {
		if (document.readyState === 'complete') {
			// The page is fully loaded
			console.log('init maps');
			maps.initMaps();
		} else {
			document.onreadystatechange = () => {
				if (document.readyState === 'complete') {
					// document ready
					console.log('init maps');
					maps.initMaps();
				}
			};
		}
	},
	helpers: {
		requireAll: function(r) {
			r.keys().forEach(r);
		},
		initSvg: function (){
			svgxhr({
				filename: './wp-content/themes/class/dist/sprite.svg', //URL to fetch
			});
		}

	}

	//Prefer creating new modules when appropriate, rather than adding functions here
	//If you need something to be globally accessible, you can assign an imported module to this namespace
	//I.e import Contact from './contact';
	//    window.namespace.Contact = Contact;
};

window.pinnacle.init();


if(typeof NodeList.prototype.forEach !== 'function'){
	NodeList.prototype.forEach = Array.prototype.forEach;
}

// SVG Sprite Loader
window.pinnacle.helpers.requireAll(require.context('../images/icons/', true, /\.svg$/));
