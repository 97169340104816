/**
 * Navigation
 */

export default {
    init() {
        this.initMenuToggle();
        this.initMobileSubmenuToggle();
    },
    initMenuToggle() {
        // Toggle for mobile navigation
        document.querySelectorAll('.nav-toggle').forEach(el => {
            el.addEventListener('click', e => {
                e.preventDefault();

                e.currentTarget.classList.toggle('open');
                document.querySelector('.primary-navigation').classList.toggle('open');
                document.getElementsByTagName('html')[0].classList.toggle('nav-open');
            });
        });
    },
    initMobileSubmenuToggle() {
        const primaryMenus = document.querySelector('.primary-navigation > ul');

        if (primaryMenus) {
            const menuItemsWithChildren = primaryMenus.querySelectorAll('.menu-item-has-children');

            // For each menu item with children
            for (let i = 0; i < menuItemsWithChildren.length; i++) {
                menuItemsWithChildren[i].addEventListener('click', e => {
                    // if (e.target.tagName != 'A') {
                        // When clicked toggle active class, and remove active class from other items
                        let isActive = e.currentTarget.classList.contains('active');

                        menuItemsWithChildren.forEach( el => { el.classList.toggle('active', false) });
                        e.currentTarget.classList.toggle('active', !isActive);
                    // }
                });
            }
        }
    }
}